@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:wght@100&display=swap');
@import "./styles/_variables.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --palette-ss-orange: #{$ss-orange};
  --palette-ss-000: #{$ss-000};
  --palette-ss-black: #{$ss-black};
  --palette-ss-white: #{$ss-white};
  --palette-ss-accent-color: #{$ss-accent-color};
  --palette-ss-grey-text: #{$ss-grey-text};
  --palette-ss-black-text: #{$ss-black-text};
}
